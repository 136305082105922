import React from 'react';
import TextToggle from '../../components/text-toggle/text-toggle';
import WoodHome from '../../images/wood-home-ready.png';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import Advantages from '../../components/advantages/advantages';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import PriceList from '../../components/price-list/price-list';


const GermetizatsiyaSruba = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Герметизация срубов', url:'#', status:true}]
  return (
    <div>
        <SEO title="Герметизация срубов в Калининградской области"
            description="Опытные бригады в срок выполнят работы по герметизация срубов в Калининграде.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Герметизация срубов в Калининград&shy;ской области'} 
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
            <Container fluid>
        <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify">
                <h1 className="text-md-left">Герметизация срубов</h1>
                <p>
                Возникновение щелей между венцами – достаточно распространённая проблема домов из сруба. 
                Сразу после постройки сруб выглядит гладко и аккуратно, однако время и погодные воздействия берут свое: дерево старится. 
                Нестабильность линейных размеров и объёма при изменениях температуры и влажности являются 
                характерными эксплуатационными качествами древесины. 
                Конструкция деревянного сруба находится в постоянном движении, 
                поэтому раскрытие межвенцовых стыков, как и растрескивание брёвен, неизбежны.<br />
                Ограждающую функцию такая стена полноценно выполнять не способна. 
                Тепло улетучивается через щели и ни о каком уюте речи уже быть не может. <br />
                Образуется благоприятная для гниения брёвен и размножения насекомых среда, 
                что влечёт снижение сроков эксплуатации самого дома. Кроме того, натуральные
                 волокна активно растаскиваются птицами для собственного строительства. 
                 Одним словом, традиционное проконопачивание швов далеко от идеала.
                </p>
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={WoodHome} className="w-100" style={{borderRadius:"0px 0px 150px 14px"}}  alt="" title="" />
              </Col>
            </Row>
        </Container>
        <Container fluid className="">
          <Container className="mt-2 p-1 p-md-3">
            <Row>
              <h2>Цены на работы по герметизации и окраск сруба</h2>
            </Row>
          </Container>
         </Container>
        <FormQuickOrder className="mt-5" />
        <Container>
           <Row>
             <Col md="12" className="mt-3">
               <h2>Герметизация сруба по технологии теплый шов</h2>
              <p>
              На сегодняшний день оптимальным решением проблемы межвенцовых стыков является использование
              специальных герметиков. Они обладают высокой адгезией к дереву, паропроницаемы и,
              в то же время, эффективно противостоят воздействию влаги,
              перепадов температур и ультрафиолету - не теряют своих свойств в течение многих лет.
              Обработка позволяет получить тёплый и долговечный за счёт эластичности герметика и 
              стойкости при температурах от -50°С до +60°С шов. Такой герметик способен растягиваться и расширяться, 
              не пропуская внешнюю влагу, но при этом не препятствуя диффузии водяных паров наружу.
              </p>
              <h2>Профессиональные услуги по герметизации  сруба в Калининграде.</h2>
              <p>Компания «Грутвуд» выполняет все виды работ по покраске и восстановлению срубов. 
                Мы используем только проверенные материалы, прошедшие необходимую сертификацию. 
                Все работы проводятся с обязательным соблюдением технологии и температурного режима.
                </p>
             </Col>
           </Row>
         </Container>
         <Advantages />
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default GermetizatsiyaSruba;